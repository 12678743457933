import React from "react"
import {graphql, Link, navigate, useStaticQuery} from "gatsby"
import {OutboundLink} from "gatsby-plugin-google-gtag";
import {setCookie} from "../../utils/global-functions";

const Footer = ({pageContext}) => {

    const lang = pageContext.locale.path.default
    const query = useStaticQuery(graphql`
        query {
            allStrapiPost {
                edges {
                    node {
                        id
                        locale
                        add_to_press
                    }
                }
            }
            allStrapiArticle {
                edges {
                    node {
                        id
                        locale
                    }
                }
            }
            allStrapiNotice {
                edges {
                    node {
                        id
                        locale
                    }
                }
            }
            menu: markdownRemark(
                frontmatter: {
                    name: {
                        eq: "menu-pages"
                    }
                }) {
                frontmatter {
                    en{
                        products{
                            url
                            name
                            description
                        }
                        services{
                            url
                            name
                            description
                            available
                        }
                        csd{
                            url
                            name
                            description
                            available
                        }
                        bsd{
                            url
                            name
                            description
                            available
                        }
                        whyTecalis{
                            url
                            name
                        }
                        company{
                            url
                            name
                        }
                        about{
                            url
                            name
                        }
                        rsc{
                            url
                            name
                            available
                        }
                        whereWeAre{
                            url
                            name
                        }
                        contact{
                            url
                            name
                        }
                        resources{
                             url
                             name
                        }
                        news{
                            url
                            name
                        }
                        techInsights{
                            url
                            name
                        }
                        events{
                            url
                            name
                        }
                        media{
                            url
                            name
                        }
                        people{
                            url
                            name
                        }
                        join{
                            url
                            name
                        }
                        students{
                            url
                            name
                        }
                        signature{
                            url
                            name
                        }
                        identity{
                            url
                            name
                        }
                        dxbot{
                            url
                            name
                        }
                        rpa{
                            url
                            name
                        }
                        bss{
                            url
                            name
                        }
                        cloudNative{
                            url
                            name
                        }
                        strategy{
                            url
                            name
                        }
                        pdc{
                            url
                            name
                        }
                        feder{
                            url
                            name
                        }
                        privacy{
                            url
                            name
                        }
                        conditions{
                            url
                            name
                        }
                        politics{
                            url
                            name
                            available
                        }
                        partners{
                            url
                            name
                        }
                        contract{
                            url
                            name
                        }
                        footer{
                            language
                            spanish
                            english
                        }
                   }
                   es{
                        products{
                            url
                            name
                            description
                        }
                        services{
                            url
                            name
                            description
                            available
                        }
                        csd{
                            url
                            name
                            description
                            available
                        }
                        bsd{
                            url
                            name
                            description
                            available
                        }
                         whyTecalis{
                            url
                            name
                        }
                        company{
                            url
                            name
                        }
                        about{
                            url
                            name
                        }
                        rsc{
                            url
                            name
                            available
                        }
                        whereWeAre{
                            url
                            name
                        }
                        contact{
                            url
                            name
                        }
                        resources{
                             url
                             name
                        }
                        news{
                            url
                            name
                        }
                        techInsights{
                            url
                            name
                        }
                        events{
                            url
                            name
                        }
                        media{
                            url
                            name
                        }
                        people{
                            url
                            name
                        }
                        join{
                            url
                            name
                        }
                        students{
                            url
                            name
                        }
                        signature{
                            url
                            name
                        }
                        identity{
                            url
                            name
                        }
                        dxbot{
                            url
                            name
                        }
                        rpa{
                            url
                            name
                        }
                        bss{
                            url
                            name
                        }
                        cloudNative{
                            url
                            name
                        }
                        strategy{
                            url
                            name
                        }
                        pdc{
                            url
                            name
                        }                        
                        feder{
                            url
                            name
                        }
                        privacy{
                            url
                            name
                        }
                        conditions{
                            url
                            name
                        }         
                        politics{
                            url
                            name
                            available
                        }      
                        partners{
                            url
                            name
                        }
                        contract{
                            url
                            name
                        }         
                        footer{
                            language
                            spanish
                            english
                        }
                   }
                }
            }
        }
    `);

    const news = query.allStrapiPost.edges.filter(post => post.node.locale === lang);
    const media = query.allStrapiNotice.edges.filter(post => post.node.locale === lang);
    const articles = query.allStrapiArticle.edges.filter(article => article.node.locale === lang);
    const menuPages = query.menu.frontmatter[lang];
    const people = lang === 'es';

    const changeLanguage = async (e) => {
        e.preventDefault();

        const lang = e.target.dataset.lang;
        const slug = lang === 'en' ? '/' : `/${lang}`;

        if (typeof window !== 'undefined') {
            setCookie("language-checked", lang, new Date("01-01-2100"));
            setTimeout(() => navigate(slug));
        }
    }

    return (
        <footer className="py-8 py-md-11 bg-grey border-top-md-footer">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-4 col-lg-2 footer-sp">

                        {/** Brand */}
                        <img src={require("../../img/brand-blue.png").default} alt="..." className="footer-brand img-fluid mb-2"
                             width="100"/>
                        <br/><br/>

                        <ul className="iso">
                            <li className="list-inline-item list-social-item mr-1">
                                <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=199259" rel="noopener noreferrer" target="_blank">
                                    <img src={require("../../img/9001-nuevo.svg").default} width="80" alt="iso9001"/>
                                </OutboundLink>
                            </li>

                            <li className="list-inline-item list-social-item mr-1">
                                <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=207780" target="_blank" rel="noreferrer">
                                    <img src={require("../../img/27001-nuevo.svg").default} width="80" alt="iso27001"/>
                                </OutboundLink>
                            </li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-4 col-lg-2 footer-sp">
                        <h5 className="font-weight-black text-blue spacing">{menuPages.products.name}</h5>

                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0 font-weight-regular spacing">
                            <li className="mb-3">
                                <Link to={menuPages.signature.url} className="text-reset font-size-sm">{menuPages.signature.name}</Link>
                            </li>
                            <li className="mb-3">
                                <Link to={menuPages.identity.url} className="text-reset font-size-sm">{menuPages.identity.name}</Link>
                            </li>
                            <li className="mb-3">
                                <Link to={menuPages.dxbot.url} className="text-reset font-size-sm">{menuPages.dxbot.name}</Link>
                            </li>
                            {/*<li className="mb-3">
                                <Link to={langSlug + "/sistemas-gestion-empresarial"} className="text-reset font-size-sm">CRM</Link>
                            </li>*/}
                        </ul>
                    </div>

                    {menuPages.services.available && <div className="col-6 col-md-4 col-lg-2 footer-sp">
                        <h5 className="font-weight-black text-blue spacing">{menuPages.services.name}</h5>

                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0 font-weight-regular spacing">
                            <li className="mb-3">
                                <Link to={menuPages.csd.url} className="text-reset font-size-sm">{menuPages.csd.name}</Link>
                            </li>
                            <li className="mb-3">
                                <Link to={menuPages.bsd.url} className="text-reset font-size-sm">{menuPages.bsd.name}</Link>
                            </li>
                            <li className="mb-3">
                                <Link to={menuPages.whyTecalis.url} className="text-reset font-size-sm">
                                    {menuPages.whyTecalis.name}
                                </Link>
                            </li>
                        </ul>
                    </div>}

                    <div className="col-6 col-md-4 col-lg-2 footer-sp">

                        {/** Heading */}
                        <h5 className="font-weight-black text-blue spacing">
                            {menuPages.company.name}
                        </h5>

                        {/** List */}
                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0 font-weight-regular spacing">
                            <li className="mb-3">
                                <Link
                                    to={menuPages.about.url}
                                    className="text-reset font-size-sm">
                                    {menuPages.about.name}
                                </Link>
                            </li>
                            {menuPages.rsc.available && <li className="mb-3">
                                <Link
                                    to={menuPages.rsc.url}
                                    className="text-reset font-size-sm">
                                    RSE
                                    {/*{menuPages.rsc.name}*/}
                                </Link>
                            </li>}
                            {menuPages.politics.available && <li className="mb-3">
                                <Link
                                    to={menuPages.politics.url}
                                    className="text-reset font-size-sm">
                                    {menuPages.politics.name}
                                </Link>
                            </li>}
                            <li>
                                <Link
                                    to={menuPages.contact.url}
                                    className="text-reset font-size-sm">
                                    {menuPages.contact.name}
                                </Link>
                            </li>
                        </ul>

                    </div>
                    <div className="col-6 col-md-4 col-lg-2">

                        {/** Heading */}
                        <h5 className="font-weight-black text-blue spacing">
                            {menuPages.resources.name}
                        </h5>

                        {/** List */}
                        <ul className="list-unstyled text-muted mb-0 font-weight-regular spacing">
                            {news.length > 0 && <li className="mb-3">
                                <Link
                                    to={menuPages.news.url}
                                    className="text-reset font-size-sm">
                                    News
                                    {/*{menuPages.news.name}*/}
                                </Link>
                            </li>}
                            {articles.length > 0 && <li className="mb-3">
                                <Link
                                    to={menuPages.techInsights.url}
                                    className="text-reset font-size-sm">
                                    {menuPages.techInsights.name}
                                </Link>
                            </li>}
                            <li className="mb-3">
                                <Link
                                    to={menuPages.events.url}
                                    className="text-reset font-size-sm">
                                    {menuPages.events.name}
                                </Link>
                            </li>
                            {media.length > 0 && <li className="mb-3">
                                <Link
                                    to={menuPages.media.url}
                                    className="text-reset font-size-sm">
                                    Medios
                                    {/*{menuPages.media.name}*/}
                                </Link>
                            </li>}
                        </ul>

                    </div>
                    <div className="col-6 col-md-4 col-lg-2 footer-sp">

                        {/** Heading */}
                        <h5 className="font-weight-black text-blue spacing">
                            {menuPages.people.name}
                        </h5>

                        {/** List */}
                        <ul className="list-unstyled text-muted mb-0 font-weight-regular spacing">
                            <li className="mb-3">
                                <Link to={menuPages.people.url} className="text-reset font-size-sm">
                                    {menuPages.people.name}
                                </Link>
                            </li>
                            {people && <li className="mb-3">
                                <Link to={menuPages.students.url} className="text-reset font-size-sm">
                                    {menuPages.students.name}
                                    {/*{menuPages.students.name}*/}
                                </Link>
                            </li>}
                            {people && <li className="mb-3">
                                <Link to={menuPages.join.url} className="text-reset font-size-sm">
                                    {menuPages.join.name}
                                </Link>
                            </li>}

                        </ul>

                    </div>

                </div>
                {/** / .row */}

                <div className="row">

                    <div className="col-10 col-md-4 col-lg-8 footer-sp">

                        {/** Heading */}
                        <h5 className="font-weight-black text-blue spacing mt-5">
                            <i className="fe fe-globe mr-1"/> {menuPages.footer.language}
                        </h5>

                        <div className="lang-footer">
                            <a href="#" onClick={changeLanguage} data-lang="es" className="text-reset font-size-sm spacing mr-3">
                                {menuPages.footer.spanish}
                            </a>
                            <a href="#" onClick={changeLanguage} data-lang="en" className="text-reset font-size-sm spacing mr-3">
                                {menuPages.footer.english}
                            </a>
                        </div>
                    </div>
                </div>


                <div className="row">

                    <div className="col-12 col-md footer-sp">
                        <div className="mt-6">
                          <span className="text-reset font-size-sm font-weight-black spacing mr-3">
                              © {new Date().getFullYear()} · Tecalis
                            </span>

                            <Link to={menuPages.privacy.url} className="text-reset font-size-sm spacing mr-3">
                                {menuPages.privacy.name}
                            </Link>

                            <Link to={menuPages.conditions.url} className="text-reset font-size-sm spacing mr-3">
                                {menuPages.conditions.name}
                            </Link>

                            <Link to={menuPages.contract.url} className="text-reset font-size-sm spacing mr-3">
                                {menuPages.contract.name}
                            </Link>

                            <Link to={menuPages.feder.url} className="text-reset font-size-sm spacing " id="link-feder">
                                {menuPages.feder.name}
                            </Link>
                        </div>

                    </div>
                    <div className="col-12 col-md-auto">

                        <ul className="list-unstyled list-inline list-social mt-6 mb-6 mb-md-0">
                            <li className="list-inline-item list-social-item mr-3">
                                <OutboundLink href="https://www.facebook.com/tecalis/" target="_blank" rel="noopener noreferrer me"
                                              className="text-decoration-none">
                                    <i className="fe fe-facebook"/>
                                </OutboundLink>
                            </li>
                            <li className="list-inline-item list-social-item mr-3">
                                <OutboundLink href="https://twitter.com/tecalis" className="text-decoration-none" target="_blank" rel="noopener noreferrer me">
                                    <i className="fe fe-twitter"/>
                                </OutboundLink>
                            </li>
                            <li className="list-inline-item list-social-item mr-3">
                                <OutboundLink href="https://www.linkedin.com/company/tecalis/" className="text-decoration-none"
                                              target="_blank" rel="noopener noreferrer me">
                                    <i className="fe fe-linkedin"/>
                                </OutboundLink>
                            </li>
                            <li className="list-inline-item list-social-item mr-3">
                                <OutboundLink href="https://www.instagram.com/tecalis/" target="_blank" rel="noopener noreferrer me"
                                              className="text-decoration-none">
                                    <i className="fe fe-instagram"/>
                                </OutboundLink>
                            </li>

                            <li className="list-inline-item list-social-item">
                                <OutboundLink href="https://www.youtube.com/c/Tecalis" target="_blank" rel="noopener noreferrer me"
                                              className="text-decoration-none">
                                    <i className="fe fe-youtube"/>
                                </OutboundLink>
                            </li>

                        </ul>


                    </div>
                </div>
            </div>
            {/** / .container */}

            <div className="custom-cursor" id="cursors"/>

        </footer>

    )
}

export default Footer
