import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import {documentReady, getCurrentPage} from "../../utils/global-functions";
import scrollTo from "gatsby-plugin-smoothscroll";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import logo from "../../img/brand.png";

const Nav = ({pageContext}) => {
    const lang = pageContext.locale.path.default
    const langSlug = '/' + lang;
    const homeSlug = pageContext.locale.path.default !== 'en' ? langSlug : '/';
    const currentHref = typeof window !== 'undefined' && window.document ? window?.location.href : '';
    const currentPage = getCurrentPage(currentHref);
    const query = useStaticQuery(graphql`
    query {
        allStrapiPost {
            edges {
                node {
                    id
                    locale
                    add_to_press
                }
            }
        }
        allStrapiArticle {
            edges {
                node {
                    id
                    locale
                }
            }
        }
        allStrapiNotice {
            edges {
                node {
                    id
                    locale
                }
            }
        }
        menu: markdownRemark (
            frontmatter: {
                name: { eq: "menu-pages" }
            }
        ) {
            frontmatter {
                en {
                    products{
                        url
                        name
                        description
                    }
                    signature{
                        url
                        name
                        description
                    }
                    identity{
                        url
                        name
                        description
                    }
                    dxbot{
                        url
                        name
                        description
                    }
                    links{
                        name
                        trends
                        client
                    }
                    services{
                        url
                        name
                        description
                        available
                    }
                    csd{
                        url
                        name
                        description
                        available
                    }
                    bsd{
                        url
                        name
                        description
                        available
                    }
                    whyTecalis{
                        url
                        name
                        description
                    }
                    company{
                        url
                        name
                    }
                    about{
                        url
                        name
                    }
                    rsc{
                        url
                        name
                        available
                    }
                    whereWeAre{
                        url
                        name
                    }
                    contact{
                        url
                        name
                    }
                    resources{
                         url
                         name
                    }
                    news{
                        url
                        name
                    }
                    techInsights{
                        url
                        name
                    }
                    events{
                        url
                        name
                    }
                    media{
                        url
                        name
                    }
                    people{
                        url
                        name
                    }
                    join{
                        url
                        name
                    }
                    students{
                        url
                        name
                    }
                    signature{
                        url
                        name
                    }
                    identity{
                        url
                        name
                    }
                    rpa{
                        url
                        name
                    }
                    bss{
                        url
                        name
                    }
                    cloudNative{
                        url
                        name
                    }
                    strategy{
                        url
                        name
                    }
                    pdc{
                        url
                        name
                    }
                    feder{
                        url
                        name
                    }
                    privacy{
                        url
                        name
                    }
                    politics{
                        url
                        name
                        available
                    }
                    myAccount
                    partners{
                        url
                        name
                    }
               }
                es {
                    products{
                        url
                        name
                        description
                    }
                    signature{
                        url
                        name
                        description
                    }
                    identity{
                        url
                        name
                        description
                    }
                    dxbot{
                        url
                        name
                        description
                    }
                    links{
                        name
                        trends
                        client
                    }
                    services{
                        url
                        name
                        description
                        available
                    }
                    csd{
                        url
                        name
                        description
                        available
                    }
                    bsd{
                        url
                        name
                        description
                        available
                    }
                    whyTecalis{
                        url
                        name
                        description
                    }
                    company{
                        url
                        name
                    }
                    about{
                        url
                        name
                    }
                    rsc{
                        url
                        name
                        available
                    }
                    whereWeAre{
                        url
                        name
                    }
                    contact{
                        url
                        name
                    }
                    resources{
                         url
                         name
                    }
                    news{
                        url
                        name
                    }
                    techInsights{
                        url
                        name
                    }
                    events{
                        url
                        name
                    }
                    media{
                        url
                        name
                    }
                    people{
                        url
                        name
                    }
                    join{
                        url
                        name
                    }
                    students{
                        url
                        name
                    }
                    signature{
                        url
                        name
                    }
                    identity{
                        url
                        name
                    }
                    rpa{
                        url
                        name
                    }
                    bss{
                        url
                        name
                    }
                    cloudNative{
                        url
                        name
                    }
                    strategy{
                        url
                        name
                    }
                    pdc{
                        url
                        name
                    }
                    feder{
                        url
                        name
                    }
                    privacy{
                        url
                        name
                    }
                    politics{
                        url
                        name
                        available
                    }
                    myAccount
                    partners{
                        url
                        name
                    }
               }
            }
        }
    }`);

    const news = query.allStrapiPost.edges.filter(post => post.node.locale === lang);
    const media = query.allStrapiNotice.edges.filter(post => post.node.locale === lang);
    const articles = query.allStrapiArticle.edges.filter(article => article.node.locale === lang);
    const menuPages = query.menu.frontmatter[lang];

    documentReady(() => {
        const btnHome = document.getElementById('btn-home');

        if (btnHome) {
            btnHome.addEventListener('click', e => {
                if (typeof window === 'undefined' || !window.document) {
                    return;
                }

                if (window.location.pathname === homeSlug) {
                    e.preventDefault();
                    scrollTo("#section-home");
                }
            })
        }
    });

    return (
        <div className="container-fluid">

            {/** Menu */}
            <div className="container">

                {/** Brand */}
                <Link id="btn-home" className="navbar-brand" to={homeSlug}>
                    <img src={logo} className="navbar-brand-img" alt="Tecalis" title="tecalis"/>
                </Link>

                {/** Toggler */}
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>

                {/** Collapse */}
                <div className="collapse navbar-collapse" id="navbarCollapse">

                    {/** Toggler */}
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <i className="fe fe-x"/>
                    </button>

                    {/** Navigation */}
                    <ul className="navbar-nav ml-auto">

                        <li className="nav-item dropdown">
                            <Link to={'/#'} className="nav-link dropdown-toggle" id="navbarLandings" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {menuPages.products.name}
                                {currentPage === 'products' && <span className="menu--active"/>}
                            </Link>

                            <div className="dropdown-menu dropdown-menu-xl p-0" aria-labelledby="navbarLandings">
                                <div className="row no-gutters">
                                    <div className="col-12 col-lg-7">
                                        <div className="dropdown-body">
                                            <div className="row no-gutters">
                                                <div className="col-12">
                                                    <h6 className="prod-nav-1 spacing text-black">
                                                        {menuPages.products.description}
                                                    </h6>

                                                    <hr className="black-line-nav"/>

                                                    <Link className="dropdown-item" to={menuPages.signature.url}>
                                                        <img src={require("../../img/sig-nav.png").default} className="ico-nav-sig" alt=""/> <span
                                                        className="ml-1 prod-nav">{menuPages.signature.name}</span>
                                                        <p className="desc--nav-p">{menuPages.signature.description}</p>
                                                    </Link>
                                                    <Link className="dropdown-item" to={menuPages.identity.url}>
                                                        <img src={require("../../img/iden-nav.png").default} className="ico-nav-iden" alt=""/> <span
                                                        className="ml-1 prod-nav">{menuPages.identity.name}</span>
                                                        <p className="desc--nav-p">{menuPages.identity.description}</p>
                                                    </Link>
                                                    <Link className="dropdown-item" to={menuPages.dxbot.url}>
                                                        <img src={require("../../img/dx-nav.png").default} className="ico-nav-iden" alt=""/> <span
                                                        className="ml-1 prod-nav">{menuPages.dxbot.name}</span>
                                                        <p className="desc--nav-p">{menuPages.dxbot.description}</p>
                                                    </Link>

                                                    <span className="mobile--services">
                                                        <h6 className="mt-7 dropdown-header text-black">
                                                            {menuPages.links.name}
                                                        </h6>

                                                        <Link className="dropdown-item mt-3" to={menuPages.whyTecalis.url}>
                                                            {menuPages.whyTecalis.name}
                                                        </Link>
                                                        {articles.length > 0 && <Link to={menuPages.techInsights.url} className="dropdown-item">
                                                            {menuPages.links.trends}
                                                        </Link>}

                                                        <OutboundLink href="https://account.tecalis.com" target="_blank" rel="noreferrer" className="dropdown-item mt-3">
                                                            {menuPages.links.client}
                                                        </OutboundLink>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-5">
                                        <div className="dropdown-body dropdown-img-lefts">
                                            <div className="row no-gutters">
                                                <div className="col-12">
                                                    <h6 align="left" className="prod-nav-1 text-black serv-nav">
                                                        {menuPages.links.name}
                                                        <hr className="grey-line-nav"/>
                                                    </h6>

                                                    <span className="font-weight-bold mb-0 spacing cn-nav">
                                                            <Link to={menuPages.whyTecalis.url}
                                                                  className="text-white-nav">
                                                                {menuPages.whyTecalis.name}
                                                            </Link>
                                                        </span>

                                                    <br/>

                                                    {articles.length > 0 && <>
                                                        <span className="font-weight-bold mb-0 spacing cn-nav">
                                                            <Link to={menuPages.techInsights.url} className="text-white-nav">
                                                                {menuPages.links.trends}
                                                            </Link>
                                                        </span>
                                                        <br/>
                                                    </>}

                                                    <span className="font-weight-bold mb-0 spacing cn-nav">
                                                        <OutboundLink href="https://account.tecalis.com" target="_blank" rel="noreferrer" className="text-white-nav">
                                                            {menuPages.links.client}
                                                        </OutboundLink>
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        {menuPages.services.available && <li className="nav-item dropdown">
                            <Link to={'/#'} className="nav-link dropdown-toggle" id="navbarDocumentation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {menuPages.services.name}
                                {currentPage === 'services' && <span className="menu--active"/>}
                            </Link>

                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-serv" aria-labelledby="navbarDocumentation">
                                <h6 align="left" className="prod-nav-1 text-black serv-nav mt-2 dropdown-menu-serv-1">
                                    {menuPages.services.description}
                                    <hr className="black-line-nav"/>
                                </h6>

                                <div className="list-group list-group-flush">
                                    <Link to={menuPages.csd.url} className="list-group-item">
                                        <div className="dropdown-menu-serv-1">
                                            <h3 className="font-weight-bold text-black mb-0 icon--nav-h3 spacing text-white-nav">
                                                <img src={require("../../img/dcn-nav.svg").default} className="mt-1 mb-1 mr-1" alt=""/>
                                                {menuPages.csd.name}
                                            </h3>

                                            <p className="mt-2 mb-0 icon--nav-p">
                                                {menuPages.csd.description}
                                            </p>
                                        </div>
                                    </Link>

                                    <Link to={menuPages.bsd.url} className="list-group-item">
                                        <div className="dropdown-menu-serv-1">
                                            <h3 className="font-weight-bold text-black mb-0 icon--nav-h3 spacing text-white-nav">
                                                <img src={require("../../img/aed-nav.svg").default} className="mt-1 mb-1 mr-1" alt=""/>
                                                {menuPages.bsd.name}
                                            </h3>

                                            <p className="mt-2 mb-0 icon--nav-p">
                                                {menuPages.bsd.description}
                                            </p>
                                        </div>
                                    </Link>

                                    <Link to={menuPages.whyTecalis.url}
                                          className="serv-shadow list-group-item">
                                        <div className="dropdown-menu-serv-1">
                                            <h3 className="font-weight-bold text-black mt-2 mb-0 icon--nav-h3 spacing">
                                                {menuPages.whyTecalis.name} <i
                                                className="fe fe-arrow-right ml-1 text-black fe-why-tecalis"/>
                                            </h3>

                                            <p className="text-black mt-2 mb-0 icon--nav-p1 spacing">
                                                {menuPages.whyTecalis.description}
                                                <br/><br/><br/>
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </li>}

                        {/** Compañía */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarAccount" data-toggle="dropdown" to="/#" aria-haspopup="true" aria-expanded="false">
                                {menuPages.company.name}
                                {currentPage === 'company' && <span className="menu--active"/>}
                            </Link>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item"
                                      to={menuPages.about.url}>
                                    {menuPages.about.name}
                                </Link>
                                {menuPages.rsc.available && <Link className="dropdown-item" to={menuPages.rsc.url}>
                                    {menuPages.rsc.name}
                                </Link>}
                                {menuPages.politics.available && <Link className="dropdown-item" to={menuPages.politics.url}>
                                    {menuPages.politics.name}
                                </Link>}
                                <Link className="dropdown-item"
                                      to={menuPages.contact.url}>
                                    {menuPages.contact.name}
                                </Link>
                            </div>
                        </li>

                        {/** Recursos */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarAccount" data-toggle="dropdown"
                                  to="/#"
                                  aria-haspopup="true" aria-expanded="false">
                                {menuPages.resources.name}
                                {currentPage === 'resources' && <span className="menu--active"/>}
                            </Link>
                            <div className="dropdown-menu">
                                {news.length > 0 && <Link className="dropdown-item" to={menuPages.news.url}>
                                    {menuPages.news.name}
                                </Link>}
                                {articles.length > 0 && <Link className="dropdown-item" to={menuPages.techInsights.url}>
                                    {menuPages.techInsights.name}
                                </Link>}
                                <Link className="dropdown-item" to={menuPages.events.url}>
                                    {menuPages.events.name}
                                </Link>
                                {media.length > 0 && <Link className="dropdown-item" to={menuPages.media.url}>
                                    {menuPages.media.name}
                                </Link>}
                            </div>
                        </li>

                        <li className="nav-item desktop--version">
                            <Link className="nav-link" id="navbarAccount" to={menuPages.partners.url}>
                                {menuPages.partners.name}
                                {currentPage === 'partners' && <span className="menu--active"/>}
                            </Link>
                        </li>


                        {/** People / Desktop version */}
                        <li className="nav-item desktop--version">
                            <Link className="nav-link" id="navbarAccount" to={menuPages.people.url}>
                                {menuPages.people.name}
                                {currentPage === 'people' && <span className="menu--active"/>}
                                {/*{menuPages.people.name}*/}
                            </Link>
                        </li>

                        <li className="nav-item">
                            <OutboundLink className="nav-link" id="navbarAccount" href="https://account.tecalis.com" target="_blank" rel="noreferrer">
                                <span className="secondary-nav color-portal">{menuPages.myAccount}</span>
                            </OutboundLink>
                        </li>

                    </ul>

                </div>
                {/** / .menu */}
            </div>
        </div>
    )
}

export default Nav
